html,
body,
#root {
  height: 100%;
  width: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}

body {
  background-color: #a6a294;
  font-family: "JetBrains Mono";
}
